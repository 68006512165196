<!--
  Typically placed on top of a form input.
-->
<script setup lang="ts">import { computed as _computed } from 'vue';

import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons'

withDefaults(defineProps<{
  // The text of the label.
  label?: string

  // Whether the field the label is attached to is required.
  required?: boolean

  // Whether the field the label is attached to has an error.
  hasErrors?: boolean

  // Hint to display as tooltip next to the label.
  hint?: string | object

  // Size of the label text.
  size?: 'small' | 'large'
}>(), { size: 'large', })

const isSmall = _computed(() => __props.size === 'small')
</script>

<template>
  <label
    class="text-left flex items-center cursor-inherit
      text-pretty whitespace-pre-wrap font-semibold text-navy"
    :class="isSmall ? 'text-sm' : 'text-base'"
  >{{ label }}<span
    v-if="required"
    class="ml-1"
    title="This field is required"
    :class="hasErrors && 'text-red-base'"
  >*</span><V2Icon
    v-if="hint"
    v-tooltip="typeof hint === 'string' ? { content: hint, placement: 'right' } : hint"
    class="hint cursor-pointer text-v2-tertiary mx-1.5"
    :icon="faCircleInfo"
    :size="isSmall ? 'xs' : 'sm'"
  /><slot/></label>
</template>
